// 植物多样性监测
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [
                {
                    label: "年度",
                    prop: "ND"
                }, {
                    label: "植物数量",
                    prop: "ZWSL"
                }, {
                    label: "植物类型",
                    prop: "ZWLX"
                }, {
                    label: "主要物种",
                    prop: "ZYWZ"
                }, {
                    label: "平均高度",
                    prop: "PJGD"
                },
                {
                    label: "平均胸径",
                    prop: "PJXJ"
                }, {
                    label: "郁闭度",
                    prop: "YBD"
                }, {
                    label: "密度",
                    prop: "MD"
                }, {
                    label: "树种组成",
                    prop: "WZZC"
                }, {
                    label: "总盖度",
                    prop: "ZGD"
                }, {
                    label: "平均冠幅",
                    prop: "PJGF"
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;